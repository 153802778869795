// user.selectors.ts

import { ArkSearchBarFilter } from '@ark';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';
import { distanceBetween } from 'geofire-common';

import { selectUser } from '../auth/selectors';
import { generateBoundsFromLatLng, isLocationWithinBounds } from '../helpers/geolocation';
import { UserState } from './state';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUserByUid = (uid: string) =>
  createSelector(selectUserState, (state: UserState) => state.users[uid]);

export const selectUsersByUids = (uids: string[]) =>
  createSelector(selectUserState, (state: UserState) =>
    uids.map((uid) => state.users[uid]).filter((user) => !!user),
  );

export const selectUsersByLatLngForAuthUser = (
  start = 0,
  end?: number,
  filter?: ArkSearchBarFilter,
) =>
  createSelector(
    selectUser,
    selectFilteredUsers(filter),
    (authUser, selectedUsers): { users: GthUserModel[]; total: number } => {
      const users = Object.values(selectedUsers);

      if (!users || !authUser) return { users: [], total: 0 };

      const lat = authUser.defaultCity.lat;

      const lng = authUser.defaultCity.lng;

      const radiusInM = 200 * 1000;

      const center: [number, number] = [lat, lng];

      const filteredUsers = users.filter((user) => {
        if (!user?.defaultCity?.lat || !user?.defaultCity?.lng) return false;

        const distanceInKm = distanceBetween(
          [user?.defaultCity?.lat, user?.defaultCity?.lng],
          center,
        );

        const distanceInM = distanceInKm * 1000;

        return distanceInM <= radiusInM;
      });

      const usersSortedAndSliced = filteredUsers
        .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
        .slice(start, end);

      return { users: usersSortedAndSliced, total: filteredUsers.length };
    },
  );

export const selectUsersByLatLng = (
  lat: number,
  lng: number,
  limit = 100,
  filter?: ArkSearchBarFilter,
  bounds?: google.maps.LatLngBoundsLiteral,
) =>
  createSelector(
    selectFilteredUsers(filter),
    (selectedUsers): { users: GthUserModel[]; total: number } => {
      if (!selectedUsers) return { users: [], total: 0 };

      bounds = bounds || generateBoundsFromLatLng(lat, lng, 200);

      const usersWithInBounds = selectedUsers.filter((user) =>
        isLocationWithinBounds(
          bounds,
          user,
          (user) => user.defaultCity.lat,
          (user) => user.defaultCity.lng,
        ),
      );

      const usersSortedAndSliced = usersWithInBounds
        .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
        .slice(0, limit);

      return { users: usersSortedAndSliced, total: usersWithInBounds.length };
    },
  );

export const selectUsersPlaceCount = (placeId: string) =>
  createSelector(selectUserState, (results) => {
    return Object.values(results.users).filter((user) => {
      return !!user.getCheckInsForPlaceToday(placeId).length;
    }).length;
  });

export const selectFilteredUsers = (filter: ArkSearchBarFilter) =>
  createSelector(selectUserState, (userState) => {
    const users = Object.values(userState.users);
    if (!users) return [];
    if (
      //  If no relevant filters are applied, return all users
      !filter?.gameType?.length &&
      !filter?.levels?.length &&
      !filter?.days?.length &&
      !filter?.searchText &&
      !filter?.ratings?.length
    ) {
      return users;
    }

    return users.filter((user) => {
      const matchGameType = filter.gameType?.length ?
        user.sportAvailability?.some((sport) => filter.gameType.includes(sport.sport)) :
        true;

      const matchLevels = filter.levels?.length ?
        user.sportAvailability?.some((sport) => filter.levels.includes(sport.skill)) :
        true;

      const matchDays = filter.days?.length ?
        filter.days.every(
            (day) =>
              user.userAvailability?.weeklyAvailability &&
              user.userAvailability.weeklyAvailability[day.toLowerCase()].isAvailable,
          ) :
        true;

      const matchRatings = filter.ratings?.length ?
        user.ratings?.some((rating) => filter.ratings.includes(rating.rating)) :
        true;

      const matchSearchText = filter.searchText ?
        user.fullName.toLowerCase().includes(filter.searchText.toLowerCase()) ||
          user.displayName.toLowerCase().includes(filter.searchText.toLowerCase()) ||
          user.email.toLowerCase().includes(filter.searchText.toLowerCase()) :
        true;

      return matchGameType && matchLevels && matchDays && matchRatings && matchSearchText;
    });
  });

export const selectUsersByCheckInId = (checkInId: string) =>
  createSelector(selectUserState, (state) => {
    const users = Object.values(state.users) || [];

    return users.filter((user) => user.getAllCheckIns().find((p) => p.placeId === checkInId));
  });
